import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row } from '../components/Grid';
import EpisodePlayer from '../components/EpisodePlayer';

const VideoTopicSeries = ({ data }) => {
  return (
    <Layout title="Video Topic Series" className="video-topic-series">
      <Section className="pb-0">
        <Container fullWidth>
          <Row>
            <h1 style={{ fontWeight: '700' }}>Video Topic Series</h1>
          </Row>
        </Container>
      </Section>
      <EpisodePlayer
        episodes={data.allVideoTopicSeriesYaml.edges.map((edge) => edge.node)}
      />
      {/* <Callout
          target="_blank"
          to="https://surveys.discoveryeducation.com/s3/Sustainable-Futures-Virtual-Field-Trip-Survey-short"
        >
          Did you watch the Video Topic Series? Let us know what you thought!
        </Callout> */}
    </Layout>
  );
};

export default VideoTopicSeries;

export const query = graphql`
  query VideoTopicSeriesQuery {
    allVideoTopicSeriesYaml(sort: { order: [ASC], fields: [index] }) {
      edges {
        node {
          title
          guidSrc
          posterSrc
          duration
          thmbDes
          descriptionTitle
          description
          mainActivities {
            label
            path
            type
          }
          aboutDesc
          conversationStarterDesc
          conversationStarterGrade
          starterOne
          starterTwo
          starterThree
          starterFour
          subfooterImage
          subfooterQuote
          subfooterName
          subfooterTitle
          subfooterCompany
        }
      }
    }
  }
`;
